
    import {wrap} from 'comlink';
    const createWorker = () => {
      
        const worker = new Worker(__webpack_public_path__ + "js/download-project.worker.js");
      
      return new Promise((resolve, reject) => {
        const terminate = () => {
          worker.terminate();
        };
        const onMessage = (e) => {
          if (e.data === 'ready') {
            cleanup();
            resolve({
              worker: wrap(worker),
              terminate
            });
          }
        };
        const onError = () => {
          cleanup();
          reject(new Error("Worker js/download-project.worker.js failed to load."));
        };
        const cleanup = () => {
          worker.removeEventListener('message', onMessage);
          worker.removeEventListener('error', onError);
        };
        worker.addEventListener('message', onMessage);
        worker.addEventListener('error', onError);
      });
    };
    export default createWorker;
    